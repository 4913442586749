var AETFREE = window.AETFREE || (function ($) {
        'use strict';

        var a_ajax = new AwesomeAjax('aetfree');

        var slider_fallbacks = function (count) {
            return [
                {
                    breakpoint: 1200,
                    settings: {
                        slidesToShow: count,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 1100,
                    settings: {
                        slidesToShow: count < 8 ? count : 8,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 1024,
                    settings: {
                        slidesToShow: count < 6 ? count : 6,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 768,
                    settings: {
                        slidesToShow: count < 5 ? count : 5,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 667,
                    settings: {
                        slidesToShow: count < 4 ? count : 4,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 568,
                    settings: {
                        slidesToShow: count < 3 ? count : 3,
                        slidesToScroll: count
                    }
                },
                {
                    breakpoint: 400,
                    settings: {
                        slidesToShow: count < 2 ? count : 2,
                        slidesToScroll: 1
                    }
                },
                {
                    breakpoint: 1,
                    settings: {
                        slidesToShow: 1,
                        slidesToScroll: 1
                    }
                }
            ];
        };

        function call_slider(selector, options) {
            jQuery(selector).slick(options);
        }

        function enable_slider(id, options) {
            id = parseInt(id);
            var count = parseInt(options.count),
                autoplay = options.autoplay == 1 ? true : false || false,
                speed = parseInt(options.speed),
                adaptive = options.adaptive == 1 ? true : false || false,
                selector = '#aet-layout-' + id + ' > .slider-enabled',
                options = {
                    speed: 1000,
                    draggable: false,
                    slidesToShow: count,
                    mobileFirst: true,
                    slidesToScroll: count,
                    autoplay: autoplay,
                    autoplaySpeed: speed,
                    adaptiveHeight: adaptive,
                    prevArrow: '<div type="button" title="Previous" class="aet-button slick-prev"><svg viewBox="0 0 26 26"><use xlink:href="#icon-arrow-left"></use></svg></div>',
                    nextArrow: '<div type="button" title="Next" class="aet-button slick-next"><svg viewBox="0 0 26 26"><use xlink:href="#icon-arrow-right"></use></svg></div>',
                    responsive: slider_fallbacks(count)
                };
            call_slider(selector, options);
        }

        function enable_slider_photo(id, options) {
            id = parseInt(id);
            var count = parseInt(options.count),
                autoplay = options.autoplay == 1 ? true : false || false,
                speed = parseInt(options.speed),
                adaptive = options.adaptive == 1 ? true : false || false,
                selector = '#aet-layout-' + id + ' .testimonials-photo',
                options = {
                    speed: 1000,
                    draggable: false,
                    slidesToShow: count,
                    mobileFirst: true,
                    slidesToScroll: count,
                    variableWidth: true,
                    infinite: true,
                    autoplay: autoplay,
                    autoplaySpeed: speed,
                    adaptiveHeight: adaptive,
                    lazyLoad: 'progressive',
                    prevArrow: '<div type="button" title="Previous" class="aet-button slick-prev"><svg viewBox="0 0 26 26"><use xlink:href="#icon-arrow-left"></use></svg></div>',
                    nextArrow: '<div type="button" title="Next" class="aet-button slick-next"><svg viewBox="0 0 26 26"><use xlink:href="#icon-arrow-right"></use></svg></div>',
                    responsive: slider_fallbacks(count)
                };
            call_slider(selector, options);
            $(selector).on('afterChange', function (event, slick, direction) {
                var index = slick.currentSlide;
                var item = $('#aet-layout-' + id + ' .testimonials-photo li[data-slick-index="' + index + '"]');
                select_testimonial(item);
                setTimeout(function () {
                    calc_short_text_nubbin_position(id);
                }, 1);

            });
        }

        function init_filter(id) {
            id = parseInt(id);
            var aet_filter = new AwesomeFilter({
                container: $('[group-id="' + id + '"].filter-container'),
                controls: $('[group-id="' + id + '"].filter-controls'),
                item_selector: '.isotope-item',
                control_selector: '.aet-button',
                control_active: 'aet-button-active',
                attribut: 'group-id',
                id: id
            });
            aet_filter.init();
        }

        function bind_profiles(id) {
            id = parseInt(id);
            $('[group-id="' + id + '"] [aet-profile]').on('click', function () {
                var url = $(this).attr('data-profile');
                window.open(url, '_self');
            });
        }

        function init_animations(id) {
            id = parseInt(id);
            var $container = $('#aet-layout-' + id);
            $container.addClass('aet-animations-enabled');

            AwesomeViewport.add({
                element: $container[0],
                threshold: '30%',
                enter: function () {
                    $(this).addClass('aet-transition__in');
                }
            });

            $container.find('li').each(function (i, el) {
                var $t = $(this);
                var name = get_name($t.find('.testimonial_name .group-field-content').text());
                if (!name) return;
                $(this).data('name', name).find('.photo-container').attr('data-name', 'Meet &#xa; ' + name).parent().append('<span class="aet-img-caption"><strong>Meet</strong><br>' + name + '</span>');
            });
        }

        function get_name(str) {
            var result;
            // get first word, assuming it's first name
            var index = str.indexOf(" ");
            result = index === -1 ? str : str.substring(0, index);

            return result;
        }


        function init_panel(id, animation) {
            id = parseInt(id);
            $('[group-id="' + id + '"].testimonials-box li[testimonial-id]').on('click', function (e) {
                // if clicked on single showcase photo or on outer link
                if ($(this).attr('data-showcase-block') == 'photo' || e.target.tagName == 'A' || $(e.target).parent().get(0).tagName == 'A') {
                    return;
                }
                var group_id = $(this).parents('.testimonials-box').attr('group-id');
                var testimonial_id = $(this).attr('testimonial-id');
                var prefix = group_id + '_' + testimonial_id;

                if (typeof window['aet_' + prefix + '_panel'] != 'object' && !window['la_panel_opened']) {
                    var aet_panel = new AwesomePanel({
                        id: 'aet_' + prefix,
                        direction: 'left',
                        width: '500px',
                        height: '100%',
                        animation: animation,
                        html: '#aet_testimonial_panel_' + prefix
                    });
                    aet_panel.init();
                }
            });
        }

        function select_testimonial(item) {
            var parent = $(item).parents('.aet-layout').eq(0);
            var items = $(item).siblings();
            var blocks = ['name', 'position', 'short_text', 'divider', 'source', 'rating', 'social'];
            var testimonial = item.attr('testimonial-id');
            items.removeClass('active');
            item.addClass('active');
            $.each(blocks, function (index, block) {
                $('.testimonials-' + block + ' > li', parent).hide().removeClass('active');
                $('.testimonials-' + block + ' > li[testimonial-id="' + testimonial + '"]', parent).show().addClass('active');
            });
        }

        function init_showcase(id) {
            id = parseInt(id);
            var parent = $('#aet-layout-' + id);
            var items = $('.testimonials-photo li', parent);
            $(items).on('click', function (e) {
                var item = $(this);
                select_testimonial(item);
                calc_short_text_nubbin_position(id);
            });
        }

        function calc_rating_icons_size(id) {
            var group = $('#aet-layout-' + id);
            $('.testimonial_rating .group-field-content', group).css('max-width', 'auto');

            var on_resize_handler = function () {
                var testimonials = $('li .testimonial_rating', group),
                    fill_max_width = $('li .testimonial_rating:visible', group).outerWidth();
                fill_max_width = Math.round(fill_max_width / 10) * 10;
                $('.group-field-content', testimonials).css('max-width', fill_max_width);
                var icon_max_width = $('li .rating-background:visible > *', group).outerWidth();

                $(testimonials).each(function (index, testimonial) {
                    var rating = parseInt($(testimonial).attr('data-rating'));
                    $('.rating-fill', testimonial).css('max-width', fill_max_width * rating / 100);
                    $('.rating-background > *', testimonial).each(function (index, item) {
                        $(item).css('max-height', icon_max_width);
                    });
                    $('.rating-fill > *', testimonial).each(function (index, item) {
                        $(item).css('max-width', icon_max_width);
                        $(item).css('max-height', icon_max_width);
                    });
                });
            };
            on_resize_handler();
            $(window).resize(function () {
                on_resize_handler();
            });
        }

        function calc_social_icons_size(id) {
            var group = $('#aet-layout-' + id);
            $('.testimonial_social .group-field-content', group).css('max-width', 'auto');

            var on_resize_handler = function () {
                var testimonials = $('li .testimonial_social', group) || $('.testimonials-social > li', group),
                    container = $('.testimonial_social', group),
                    container_showcase = $('.testimonials-social', group),
                    fill_max_width = container.outerWidth() === 0 ? container_showcase.outerWidth() : container.outerWidth(),
                    icon_max_width,
                    count = 0;
                fill_max_width = Math.round(fill_max_width / 10) * 10;

                $('.group-field-content', $(testimonials)).css('max-width', fill_max_width);

                $(testimonials).each(function (index, item) {
                    var icons = $('.group-field-content > a', item);
                    count = icons.length > count ? icons.length : count;
                });

                icon_max_width = fill_max_width / count;
                var origin_size = $(testimonials).css('font-size');
                if(origin_size && +AwesomeUtil.clean_number(origin_size) > icon_max_width){
                    $(testimonials).css('font-size', icon_max_width);
                    $(testimonials).each(function (index, item) {
                        var icons = $('.group-field-content > a', item);
                        $(icons).each(function (index, item) {
                            $(item).css('font-size', icon_max_width);
                        });
                    });
                }
            };
            on_resize_handler();
            $(window).resize(function () {
                on_resize_handler();
            });
        }

        function calc_short_text_nubbin_position(id) {
            var selector = '#aet-layout-' + id;
            var group = $(selector);

            var on_resize_handler = function () {
                var active = $('.testimonials-photo .slick-track li.active:not(.slick-cloned), .testimonials-photo .slick-track li.active', group),
                    short_texts = $('.testimonials-short_text', group),
                    short_text = $('.active .testimonial_short_text', short_texts),
                    photos = $('ul.testimonials-photo', group),
                    content_box = $('.group-field-content', short_text),
                    $nubbin = $('.nubbin', short_text),
                    testimonial_id = active.attr('testimonial-id'),
                    group_width = group.outerWidth(),
                    group_offset = group.offset()['left'],
                    active_width = active.outerWidth(),
                    active_offset = active.offset()['left'],
                    short_text_width = short_texts.outerWidth(),
                    nubbin_offset = active_offset - group_offset + active_width / 2 - 17 - (group_width - short_text_width) / 2,
                    blocks_offset = active_offset - group_offset + active_width / 2 - group_width / 2;
                $nubbin.css('left', 'auto');
                $('li[testimonial-id="' + testimonial_id + '"] .nubbin', short_texts).css('left', nubbin_offset);
                $('.level-2 ul.testimonials-box:not(.testimonials-photo)', group).css('left', blocks_offset);

                if ($nubbin.offset().left < content_box.offset().left) {
                    content_box.css('min-width', photos.outerWidth());
                }
            }
            setTimeout(function () {
                on_resize_handler();
            }, 14);
            $(selector + ':not(.aet-bound)').addClass('aet-bound').on('resize', function () {
                on_resize_handler();
            });
        }

        function calculate_slider_items_size(id, count) {
            var selector = '.aet-layout-single_showcase[id="aet-layout-' + id + '"]';
            var group = $(selector);
            var on_resize_handler = function () {
                var slider = $('.slick-list', group),
                    slider_width = slider.outerWidth(),
                    items = $('.slick-slide', group),
                    item_max_width = slider_width / count;
                items.css('max-width', item_max_width);

            }
            on_resize_handler();
            $(selector + ':not(.aet-slider-bound)').addClass('aet-slider-bound').on('resize', function () {
                on_resize_handler();
            });
        }

        return {
            'enable_slider': enable_slider,
            'enable_slider_photo': enable_slider_photo,
            'init_animations': init_animations,
            'init_filter': init_filter,
            'init_panel': init_panel,
            'bind_profiles': bind_profiles,
            'calc_rating_icons_size': calc_rating_icons_size,
            'calc_social_icons_size': calc_social_icons_size,
            'init_showcase': init_showcase,
            'calc_short_text_nubbin_position': calc_short_text_nubbin_position,
            'calculate_slider_items_size': calculate_slider_items_size,
        };
    })(jQuery);